import React, { lazy, Suspense, useEffect, useState } from "react";
import Loading from "routes/loading/loadingPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import { GameContextProvider } from "tools/gameContext";
import { UserContextProvider } from "tools/userContext";

const GamePage = lazy(() => import("routes/game/gamePage"));
const LeaderBoardPage = lazy(() =>
  import("routes/leaderboard/leaderBoardPage")
);
const GameListPage = lazy(() => import("routes/gameList/gameListPage"));
const MainPage = lazy(() => import("routes/main/mainPage"));
const ChallengePage = lazy(() => import("routes/challenge/ChallengePage"));

const tele = window.Telegram.WebApp;

function App() {
  useEffect(() => {
    tele.ready();
  }, []);

  window.Telegram.WebApp.expand();
  window.Telegram.WebApp.enableClosingConfirmation();
  function testhandler(object) {
    if (!this.isExpanded) {
      this.expand();
    }
  }
  window.Telegram.WebApp.onEvent("viewportChanged", testhandler);

  return (
    <React.Fragment>
      <GameContextProvider>
        <UserContextProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route
                path="/game/:game_id/:leaderboard_id"
                element={<GamePage isOnTournament={false} />}
              />
              <Route path="/board" element={<GameListPage />} />
              <Route path="/board/:game_id" element={<LeaderBoardPage />} />
              <Route path="/challenges" element={<ChallengePage />} />
              {/* <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              /> */}
            </Routes>
          </Suspense>
        </UserContextProvider>
      </GameContextProvider>
    </React.Fragment>
  );
}

export default App;
